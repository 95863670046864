import { FormSpec, multiSelectStylePicker, templateSpec } from "../../../form-specs";
import { NumberMultiSelectOption, StringMultiSelectOption } from "../../mxts/selectOption.types";
import { amenityCategoryMultiSelect, getContentTypeSelector, getMultiSelectOptions } from "../../../components/utils";

import { getI18nLocaleObject } from "../../../i18n";
import namespaceList from "../../../i18n/namespaceList";
import { webcontentSpecWithoutPreview } from "../../../form-specs/models/autocompleteWebContent";

export interface WidgetOptions {
    contentType?: string;
    resortMultiSelector?: NumberMultiSelectOption[];
    resourceId?: number;
    unitId?: number;
    amenityGroupType: string;
    amenityCategories: NumberMultiSelectOption[];
    styleIds?: string[];
    defaultNumberOfAmenities: number;
    combineOneAmenityFromAll?: boolean;
    showAmenityLinks?: boolean;
    infoSeparation: string;
    addIcon: boolean;
    displayOnlyIcon: boolean;
    showBadge: boolean;
    badgeBackgroundColor: string;
    uspTextColor: string;
    uspIconColor: string;
    sortLinksByPriority: boolean;
    enableBookingsEngineLink: boolean;
    contentTypeSelector?: boolean;
    showAmenityField: boolean;
    amenityFieldMultiSelect?: StringMultiSelectOption[];
    showIconAtTop: boolean;
    orderByCategorySequence?: boolean;
    fallbackWebContentId?: string;
    fallbackTemplateId?: string;
}

const TARGETS = ["dynamic-container", "usp-amenities"];

export const widgetOptionsForm: FormSpec<WidgetOptions> = {
    id: "amenity-widget-options",
    name: getI18nLocaleObject(namespaceList.amenitiesWidget, "uspAmenityWidgetOption"),
    pluralName: getI18nLocaleObject(namespaceList.amenitiesWidget, "uspAmenityWidgetOptions"),
    properties: [
        {
            type: "statictabs",
            tabs: [
                {
                    name: getI18nLocaleObject(namespaceList.admin, "general"),
                    properties: [
                        [
                            {
                                type: "paragraph",
                                label: getI18nLocaleObject(namespaceList.amenitiesWidget, "showUSPAmenitiesContent"),
                            },
                            ...getContentTypeSelector(),
                            amenityCategoryMultiSelect("amenityCategories"),
                            {
                                variable: "amenityGroupType",
                                type: "select",
                                default: "typesearch",
                                optionList: [
                                    {
                                        value: "typesearch",
                                        label: getI18nLocaleObject(namespaceList.amenitiesWidget, "typesearch"),
                                    },
                                    {
                                        value: "objectsearch",
                                        label: getI18nLocaleObject(namespaceList.amenitiesWidget, "objectsearch"),
                                    },
                                ],
                            },
                            {
                                variable: "orderByCategorySequence",
                                label: getI18nLocaleObject(namespaceList.amenitiesWidget, "orderByCategorySequence"),
                                type: "checkbox",
                            },
                            {
                                variable: "defaultNumberOfAmenities",
                                type: "number",
                                label: getI18nLocaleObject(namespaceList.amenitiesWidget, "defaultNumberOfAmenities"),
                            },
                            {
                                variable: "combineOneAmenityFromAll",
                                type: "checkbox",
                                label: getI18nLocaleObject(namespaceList.amenitiesWidget, "combineOneAmenityFromAll"),
                                visible: (options: WidgetOptions) => Array.from(options.amenityCategories).length > 1,
                            },
                            {
                                variable: "showAmenityLinks",
                                type: "checkbox",
                                label: getI18nLocaleObject(namespaceList.amenitiesWidget, "showAmenityLinks"),
                            },
                            {
                                variable: "sortLinksByPriority",
                                type: "checkbox",
                                label: getI18nLocaleObject(namespaceList.amenitiesWidget, "sortLinksByPriority"),
                                default: false,
                            },
                            {
                                variable: "enableBookingsEngineLink",
                                label: getI18nLocaleObject(namespaceList.dynamicPlugin, "enableBookingsEngineLink"),
                                type: "checkbox",
                            },
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.admin, "style"),
                    properties: [
                        [multiSelectStylePicker("styleIds", TARGETS)],
                        [{ variable: "uspTextColor", label: getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "uspTextColor"), type: "dual-color", default: "color-brand" }],
                        [
                            {
                                variable: "infoSeparation",
                                type: "select",
                                optionList: [
                                    {
                                        value: "comma",
                                        label: getI18nLocaleObject(namespaceList.amenitiesWidget, "infoSeparationComma"),
                                    },
                                    {
                                        value: "dots",
                                        label: getI18nLocaleObject(namespaceList.amenitiesWidget, "infoSeparationDots"),
                                    },
                                ],
                            },
                        ],
                        [
                            {
                                variable: "addIcon",
                                label: getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "addIcon"),
                                default: false,
                                type: "checkbox",
                                groupName: "iconGroup",
                                groupTitle: getI18nLocaleObject(namespaceList.admin, "iconGroupTitle"),
                            },
                            {
                                variable: "showIconAtTop",
                                type: "checkbox",
                                label: getI18nLocaleObject(namespaceList.amenitiesWidget, "showIconAtTop"),
                                groupName: "iconGroup",
                                visible: (options: WidgetOptions) => options.addIcon,
                            },
                            {
                                variable: "uspIconColor",
                                label: getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "uspIconColor"),
                                type: "dual-color",
                                default: "color-brand",
                                groupName: "iconGroup",
                                visible: (options: WidgetOptions) => options.addIcon,
                            },
                            {
                                variable: "displayOnlyIcon",
                                label: getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "displayOnlyIcon"),
                                default: false,
                                type: "checkbox",
                            },
                            {
                                variable: "showBadge",
                                label: getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "showBadge"),
                                default: false,
                                type: "checkbox",
                            },
                            {
                                variable: "badgeBackgroundColor",
                                label: getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "badgeBackgroundColor"),
                                type: "dual-color",
                                default: "background-gray",
                                visible: (options: WidgetOptions) => options.showBadge,
                            },
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.admin, "options"),
                    properties: [
                        [
                            {
                                variable: "showAmenityField",
                                label: getI18nLocaleObject(namespaceList.dynamicPlugin, "showAmenityField"),
                                default: false,
                                type: "checkbox",
                            },
                            {
                                variable: "amenityFieldMultiSelect",
                                label: getI18nLocaleObject(namespaceList.facilityWidget, "contentTypeMultiSelector"),
                                type: "multiselect",
                                optionList: () => getMultiSelectOptions(),
                                visible: (options: WidgetOptions): boolean => options.showAmenityField,
                            },
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.admin, "extra"),
                    properties: [
                        [
                            {
                                type: "paragraph",
                                label: getI18nLocaleObject(namespaceList.admin, "extraInfoDescription"),
                            },
                            {
                                variable: "fallbackWebContentId",
                                label: getI18nLocaleObject(namespaceList.admin, "fallbackWebContentId"),
                                type: "autocomplete",
                                refType: webcontentSpecWithoutPreview,
                                placeholder: getI18nLocaleObject(namespaceList.admin, "fallbackWebContentId"),
                            },
                            {
                                variable: "fallbackTemplateId",
                                label: getI18nLocaleObject(namespaceList.admin, "fallbackTemplateId"),
                                type: "autocomplete",
                                refType: templateSpec,
                                placeholder: getI18nLocaleObject(namespaceList.admin, "fallbackTemplateId"),
                            },
                        ],
                    ],
                },
            ],
        },
    ],
};
