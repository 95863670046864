import * as MXTS from "@maxxton/cms-mxts-api";

import { Content, getContent } from "../../../utils/content.util";
import { ServerSideProps, WidgetOptionsMap } from "../../../components/utils";
import { getAmenities, getAmenityIcons } from "../../mxts/amenity/mxts";

import { Activity } from "../../page/activityPlanner/activityPlanner.types";
import { AmenityIcons } from "../../mxts/amenity/Amenity";
import { CMSProviderProperties } from "../../../containers/cmsProvider.types";
import { WidgetOptions } from "./";
import { cancelable } from "../../../promise";
import { getMxtsEnv } from "../../mxts";

interface notifyParentNoDynamicContentWasFound<U> {
    callback: (thisContext: U) => void;
    thisContext?: U;
}

export interface UspAmenitiesResponse {
    finalAmenities?: MXTS.AmenityLink[];
    amenityIcons?: AmenityIcons[];
    categoryLabel?: string;
    amenityImages?: MXTS.ImageDetailedView[];
    cancelFetch?: () => void;
}

export const getUspAmenities = async <P extends keyof WidgetOptionsMap, U = undefined>(props: ServerSideProps<P>, notifyParentNoDynamicContentWasFound?: notifyParentNoDynamicContentWasFound<U>) => {
    const controller = new AbortController();
    const { options, context, availabilityState } = props;
    const widgetOptions = options as WidgetOptions;

    let categoryLabel = "";

    const amenityCategories = Array.from(widgetOptions.amenityCategories);
    const arrayOfCategoryIds: number[] = [];
    // Getting all the categories ids from Options and putting them in an array
    for (const singleCategory of amenityCategories) {
        arrayOfCategoryIds.push(singleCategory.value);
    }

    const content = (await getContent(props)) as Exclude<Content, MXTS.Resort[]>;
    const amenityManagerId = (content as Exclude<Content, Activity | MXTS.Resort[]>)?.amenityManagerId;

    if (amenityManagerId) {
        try {
            let finalAmenities: MXTS.AmenityLink[] = [];
            const [fetchedAmenities, cancelFetchingAmenities] = cancelable(
                getAmenities(context, {
                    amenityManagerId,
                    origins: ["SELF", "PARENT"],
                    orderByCategorySequence: widgetOptions.orderByCategorySequence,
                    context,
                    size: +widgetOptions.defaultNumberOfAmenities,
                    amenityCategoryIds: arrayOfCategoryIds,
                    sortLinks: widgetOptions.sortLinksByPriority,
                    signal: controller.signal,
                })
            );
            const cancelFetch = () => {
                cancelFetchingAmenities();
                controller.abort();
            };
            const amenityLinks = (await fetchedAmenities) || [];
            // Check if the amenity is available or not for Unit
            if (widgetOptions.showAmenityLinks) {
                finalAmenities = amenityLinks.filter((amenity: MXTS.AmenityLink) => {
                    const amenities = availabilityState.availabilityResult?.response.amenities || [];
                    return amenities.some((_amenity) => _amenity.key === amenity.amenityId);
                });
            } else {
                // If amenities cant be filtered using anchor-links, no need to compare it with availibilty
                finalAmenities = amenityLinks;
            }

            // If all amenities  from 1 category are to be shown
            if (arrayOfCategoryIds.length === 1 && finalAmenities.length) {
                categoryLabel = (amenityCategories?.[0] as { value: number; label: string }).label;
            }
            // If more than 1 category selected, selecting only 1st amenity from each category
            if (widgetOptions.combineOneAmenityFromAll && arrayOfCategoryIds.length > 1 && amenityLinks.length) {
                for (const inputCategory of arrayOfCategoryIds) {
                    const allAmenities = finalAmenities.filter((amenity: any) => amenity.amenityCategoryIds.indexOf(inputCategory) > -1);
                    if (allAmenities.length) {
                        finalAmenities.push(allAmenities[0]);
                    }
                }
            }
            const amenityIcons = await getAmenityIcons(finalAmenities);
            const amenityImages = await getAmenityImagesForAmenitiesWithoutIcon({ finalAmenities, amenityIcons, context });

            const response = { finalAmenities, amenityIcons, categoryLabel, amenityImages, cancelFetch };
            if (notifyParentNoDynamicContentWasFound?.callback && notifyParentNoDynamicContentWasFound?.thisContext && !finalAmenities.length) {
                notifyParentNoDynamicContentWasFound.callback(notifyParentNoDynamicContentWasFound.thisContext);
            }
            return response;
        } catch (err) {
            return;
        }
    } else {
        // If the UspAmenities have a parent that is waiting for dynamic content, notify parent when nothing was found (no amenity manager present)

        if (notifyParentNoDynamicContentWasFound?.callback && notifyParentNoDynamicContentWasFound?.thisContext) {
            notifyParentNoDynamicContentWasFound.callback(notifyParentNoDynamicContentWasFound.thisContext);
        }
    }
};

export const getAmenityImagesForAmenitiesWithoutIcon = async (params: {
    finalAmenities: MXTS.AmenityLink[];
    context: CMSProviderProperties;
    amenityIcons: AmenityIcons[];
}): Promise<MXTS.ImageDetailedView[]> => {
    const { finalAmenities: linkedAmenities, context, amenityIcons } = params;
    const allImageManagerIds: number[] = linkedAmenities
        .filter((linkedAmenity) => !amenityIcons.some((amenityIcon) => amenityIcon.amenityId === linkedAmenity.amenityId))
        .map((linkedAmenity) => linkedAmenity.amenity.imageManagerId)
        .filter((imageManagerId): imageManagerId is number => !!imageManagerId);
    const env = await getMxtsEnv(context, context.currentLocale.code);
    if (!allImageManagerIds.length) {
        return [];
    }
    const allImages = await context.mxtsApi.imagesForAllManagerId(env, {
        imageManagerIds: allImageManagerIds,
        size: allImageManagerIds.length,
        view: "detail",
    });
    return allImages || [];
};
