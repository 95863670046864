import * as FontAwesome from "react-fontawesome";
import * as MXTS from "@maxxton/cms-mxts-api";
import * as React from "react";
import * as classNames from "classnames";

import { AccommodationType, Unit, getMxtsEnv } from "../../mxts";
import { AmenityIcons, IconLibraryId } from "../../mxts/amenity/Amenity";
import { CMSProvidedProperties, CMSProviderProperties } from "../../../containers/cmsProvider.types";
import { WebContent as CmsApiWebContent, WithId } from "@maxxton/cms-api";
import { MultiSelectOptions, ServerSideProps, getHideWidgetClass, isClientLoggedIn, isEqual } from "../../../components/utils";
import { PageWidgetSpec, ResultOptions, TypesearchContainerWidgetSpec, Widget, WidgetSpec, WidgetType } from "../../widget";
import { UspAmenitiesResponse, getUspAmenities } from "./uspAmenities.utils";
import { WidgetOptions, widgetOptionsForm } from "./";
import { getI18nLocaleObject, wrapProps } from "../../../i18n";
import { getWebContentById, renderNoResultsFoundContent, renderTemplateById } from "../containerWidget.util";

import { ActionType } from "../../../redux/actions";
import { AvailabilityState } from "../../../redux/reducers/availability.types";
import { Dispatch } from "redux";
import { WidgetOptions as DynamicContainerOptions } from "../container/container.types";
import { DynamicFilter } from "../../../redux/reducers/dynamicFilter.types";
import { DynamicWidgetBaseProps } from "../dynamicWidget.types";
import { FilterChangeAction } from "../../../redux/actions/dynamicFilterAction.types";
import { Loader } from "../../../components/Loader";
import { MyEnvState } from "../../../redux/reducers/myEnv/myEnvState";
import { SitemapPageLinkWidgetOptions } from "../../sitemap/sitemap.types";
import { SmartLink } from "../../../components/SmartLink";
import { State } from "../../../redux";
import { UrlParamsUtil } from "../../../utils/urlparam.util";
import { WidgetGroup } from "../../widget.enum";
import { connect } from "react-redux";
import { dynamicFilterType } from "../../../redux/reducers/dynamicFilter.enum";
import { findMultiSelectStyleClassNames } from "../../../themes";
import { isContentFilterChanged } from "../../../utils/content.util";
import { isServerSide } from "../../../utils/generic.util";
import namespaceList from "../../../i18n/namespaceList";
import { setPageViewEvent } from "../../resultsPanel/resultsPanelUtil";

export interface USPAmenitiesProps extends DynamicWidgetBaseProps<WidgetOptions>, USPAmenitiesStoreProps, USPAmenitiesDispatchProps, USPAmenitiesParentProps {
    accommodationType?: AccommodationType;
    unit?: Unit;
    resort?: MXTS.Resort;
    unitBookUri?: string;
    amenityCodes?: string[];
}

interface USPAmenitiesState {
    isAmenitiesLoading: boolean;
    disableWidget: boolean;
    amenityLinks: MXTS.AmenityLink[];
    amenityCategory: string;
    amenityIcons: AmenityIcons[];
    fallbackWebContent?: CmsApiWebContent & WithId;
    fallbackTemplate?: JSX.Element[];
    amenityImages: MXTS.ImageDetailedView[];
}

interface USPAmenitiesStoreProps {
    dynamicFilter: DynamicFilter;
    myEnvState: MyEnvState;
    availabilityState: AvailabilityState;
}

interface USPAmenitiesParentProps {
    notifyParentNoDynamicContentWasFound?: Function;
}

interface USPAmenitiesDispatchProps {
    dispatchAction: Dispatch<FilterChangeAction>;
}

class USPAmenitiesWidget extends React.Component<USPAmenitiesProps, USPAmenitiesState> {
    private cancelFetchingAmenities?: () => void;
    private _isMounted = false;

    public static async warmupCache(props: USPAmenitiesProps): Promise<void> {
        await USPAmenitiesWidget.setUSPAmenities(props);
    }

    constructor(props: USPAmenitiesProps) {
        super(props);
        const { finalAmenities, categoryLabel, amenityIcons, amenityImages } = props?.fetchedData ?? ({} as UspAmenitiesResponse);
        this.state = {
            isAmenitiesLoading: false,
            disableWidget: true,
            amenityLinks: finalAmenities || [],
            amenityCategory: categoryLabel || "",
            amenityIcons: amenityIcons || [],
            amenityImages: amenityImages || [],
        };
        USPAmenitiesWidget.setUSPAmenities(props, this);
    }

    public componentDidMount() {
        this._isMounted = true;
        this.setState({ disableWidget: !isClientLoggedIn() });
        this.loadNoResultFoundFallback();
    }

    private static async getAmenityImagesForAmenitiesWithoutIcon(params: {
        finalAmenities: MXTS.AmenityLink[];
        context: CMSProviderProperties;
        amenityIcons: AmenityIcons[];
    }): Promise<MXTS.ImageDetailedView[]> {
        const { finalAmenities: linkedAmenities, context, amenityIcons } = params;
        const allImageManagerIds: number[] = linkedAmenities
            .filter((linkedAmenity) => !amenityIcons.some((amenityIcon) => amenityIcon.amenityId === linkedAmenity.amenityId))
            .map((linkedAmenity) => linkedAmenity.amenity.imageManagerId)
            .filter((imageManagerId): imageManagerId is number => !!imageManagerId);
        const env = await getMxtsEnv(context, context.currentLocale.code);
        if (!allImageManagerIds.length) {
            return [];
        }
        const allImages = await context.mxtsApi.imagesForAllManagerId(env, {
            imageManagerIds: allImageManagerIds,
            size: allImageManagerIds.length,
            view: "detail",
        });
        return allImages || [];
    }

    public UNSAFE_componentWillReceiveProps(nextProps: Readonly<USPAmenitiesProps>) {
        const { unit, accommodationType, resort, isMyEnvWidget, myEnvState } = this.props;
        const newUnit = nextProps.unit;
        const newAccommodationType = nextProps.accommodationType;
        const newResort = nextProps.resort;
        // To avoid unnecessary updates
        if (
            (unit && newUnit && unit.unitId !== newUnit.unitId) ||
            (accommodationType && newAccommodationType && accommodationType.resourceId !== newAccommodationType.resourceId) ||
            (resort && newResort && resort.resortId !== newResort.resortId) ||
            isContentFilterChanged(this.props, nextProps, false)
        ) {
            USPAmenitiesWidget.setUSPAmenities(nextProps, this);
        }
    }

    public componentWillUnmount() {
        this._isMounted = false;
        this.cancelFetchingAmenities?.();
    }

    public shouldComponentUpdate(nextProps: USPAmenitiesProps, nextState: USPAmenitiesState) {
        return this.state.isAmenitiesLoading !== nextState.isAmenitiesLoading || !isEqual(this.state, nextState);
    }

    // eslint-disable-next-line max-lines-per-function
    public render(): JSX.Element | null {
        const { className, options, context, unitBookUri, dynamicFilter, accommodationType, unit, amenityCodes } = this.props;
        const { fallbackWebContent, fallbackTemplate } = this.state;
        const hideWidget = getHideWidgetClass(this.props.options, this.state.disableWidget);
        const bookUrl = UrlParamsUtil.getBookingsEngineUrl(context.currentLocale.code, unit, accommodationType, unitBookUri, dynamicFilter, amenityCodes);
        const callSetViewEvent = () => {
            setPageViewEvent(context, bookUrl, accommodationType, unit);
        };
        if (hideWidget === null) {
            return null;
        }
        if (this.state.isAmenitiesLoading) {
            return (
                <div
                    // eslint-disable-next-line max-len
                    className={`usp-amenities ${options.addIcon ? "amenity-icon-added" : ""} ${options.displayOnlyIcon ? "remove-text" : ""} ${
                        this.state.amenityCategory ? this.state.amenityCategory.split(" ").join("-") : "multiple"
                    } ${hideWidget}`}
                >
                    <Loader type="description" />
                </div>
            );
        }

        return this.state.amenityLinks.length > 0 ? (
            <div
                // eslint-disable-next-line max-len
                // className={`usp-amenities ${options.addIcon ? "amenity-icon-added" : ""} ${options.displayOnlyIcon ? "remove-text" : "with-text"} ${
                //     this.state.amenityCategory ? this.state.amenityCategory.split(" ").join("-") : "multiple"
                // } ${hideWidget}`}
                className={classNames("usp-amenities", className, { "amenity-icon-added": options.addIcon })}
            >
                {this.state.amenityLinks.map((amenityLink, index) =>
                    options.enableBookingsEngineLink ? (
                        <SmartLink key={index} href={bookUrl} target="_blank" rel="noreferrer" onClick={callSetViewEvent}>
                            {amenityLink.amenity.iconLibraryId || amenityLink.amenity.imageManagerId ? (
                                <span
                                    // eslint-disable-next-line max-len
                                    className={`amenity-info amenity-wrapper-${amenityLink.amenity.identifier} ${options.infoSeparation ? `separation separate-${options.infoSeparation}` : "mr-2"}`}
                                >
                                    {this.getIconsFromLibrary(amenityLink)}
                                    {amenityLink.type === "NUMBER" && amenityLink.numberValue !== null ? <span>{amenityLink.numberValue}</span> : null}
                                </span>
                            ) : (
                                <span
                                    // eslint-disable-next-line max-len
                                    className={`amenity-info amenity-wrapper-${amenityLink.amenity.identifier} ${options.infoSeparation ? `separation separate-${options.infoSeparation}` : "mr-2"}`}
                                >
                                    <i className={"amenity"} />
                                    {amenityLink.type === "NUMBER" && amenityLink.numberValue != null ? <span>{`${amenityLink.numberValue} `}</span> : null}
                                    {this.getAmenityFieldsData(amenityLink)}
                                </span>
                            )}
                        </SmartLink>
                    ) : (
                        <span
                            key={index}
                            // eslint-disable-next-line max-len
                            className={classNames("amenity-info", "amenity-wrapper-" + amenityLink.amenity.identifier, {
                                ["color-" + options.uspTextColor]: options.uspTextColor?.includes("theme"),
                                ["seperate-" + options.infoSeparation]: !!options.infoSeparation,
                                ["background-color-" + options.badgeBackgroundColor]: options.showBadge && options.badgeBackgroundColor?.includes("theme"),
                                "seperation": !!options.infoSeparation,
                                "mr-2": !options.infoSeparation,
                                "badge badge-primary mr-1": options.showBadge,
                            })}
                            style={{
                                color: options.uspTextColor && options.uspTextColor?.includes("rgba") ? options.uspTextColor : "",
                                backgroundColor: options.showBadge && options.badgeBackgroundColor?.includes("rgba") ? options.badgeBackgroundColor : "",
                            }}
                        >
                            <React.Fragment>
                                {this.getIconsFromLibrary(amenityLink)}
                                {options.showAmenityLinks && (
                                    <a className="amenity-info-link" onClick={this.handleAmenity.bind(this, amenityLink.amenityId.toString())}>
                                        {this.getAmenityFieldsData(amenityLink)}
                                    </a>
                                )}
                                {!options.addIcon && (
                                    <>
                                        <i className="amenity" />
                                        {this.getAmenityFieldsData(amenityLink)}
                                    </>
                                )}
                            </React.Fragment>
                        </span>
                    )
                )}
            </div>
        ) : fallbackWebContent || fallbackTemplate?.length ? (
            renderNoResultsFoundContent({ noResultsFoundWebContent: fallbackWebContent, noResultsFoundTemplate: fallbackTemplate, context })
        ) : null;
    }

    private getIconsFromLibrary = (amenityLink: MXTS.AmenityLink) => {
        const { options } = this.props;
        // Check icon set is from font-awesome library
        if (amenityLink.amenity.iconLibraryId === IconLibraryId.FontAwesome) {
            return (
                <div className="icon-wrapper icon-library__font-awesome">
                    {options.addIcon && (
                        <div className={classNames("icon-wrapper__icon", { "icon-top": options.showIconAtTop, "icon-inline": !options.showIconAtTop })}>
                            <FontAwesome
                                name={amenityLink.amenity.iconName?.split(/-(.*)/)[1] || ""}
                                className={`font-awesome__icon amenity-${amenityLink.amenity.identifier} ${
                                    options.uspIconColor?.includes("theme") ? `color-${options.uspIconColor}` : !options.uspIconColor?.includes("rgba") ? options.uspIconColor : ""
                                }`}
                                style={{ color: options.uspIconColor?.includes("rgba") ? options.uspIconColor : undefined }}
                            />
                        </div>
                    )}
                    {amenityLink.type === "NUMBER" && amenityLink.numberValue ? <span className="amenity-value">{`${amenityLink.numberValue} `}</span> : null}
                    {this.getAmenityFieldsData(amenityLink)}
                </div>
            );
            // Check icon set is from stream-line library
        } else if (amenityLink.amenity.iconLibraryId === IconLibraryId.StreamLine) {
            const amenityIcon = this.state.amenityIcons.find((amenity) => amenity.amenityId === amenityLink.amenityId)?.amenityIcon;
            const iconColor = options.uspIconColor?.includes("rgba") ? options.uspIconColor : undefined;
            return (
                <div className="icon-wrapper icon-library__stream-line">
                    {options.addIcon && (
                        <div className={classNames({ "icon-top": options.showIconAtTop, "icon-inline": !options.showIconAtTop })}>
                            {amenityIcon && (
                                <div
                                    className={`stream-line__svg icon-wrapper__icon ${
                                        options.uspIconColor?.includes("theme") ? `color-${options.uspIconColor}` : !options.uspIconColor?.includes("rgba") ? options.uspIconColor : ""
                                    }`}
                                    style={{ color: iconColor, fill: iconColor }}
                                    dangerouslySetInnerHTML={{ __html: amenityIcon }}
                                />
                            )}
                        </div>
                    )}
                    {amenityLink.type === "NUMBER" && amenityLink.numberValue ? <span className="amenity-value">{`${amenityLink.numberValue} `}</span> : null}
                    {this.getAmenityFieldsData(amenityLink)}
                </div>
            );
        } else if (!amenityLink.amenity.iconLibraryId) {
            const amenityImage = this.state.amenityImages.find((amenityImage) => amenityImage.imageManagerIds?.some((imageManagerId) => imageManagerId === amenityLink.amenity.imageManagerId));
            return (
                options.addIcon &&
                amenityImage && (
                    <div className={classNames("icon-image", { "icon-top": options.showIconAtTop, "icon-inline": !options.showIconAtTop })}>
                        {amenityImage && <img src={amenityImage.urls.small.replace("t_newyse_small", "t_mcms_small")} className={classNames("amenity-image")} />}
                        {this.getAmenityFieldsData(amenityLink)}
                        {amenityLink.type === "NUMBER" && amenityLink.numberValue && <span className="amenity-value">{`${amenityLink.numberValue} `}</span>}
                    </div>
                )
            );
        }

        return null;
    };

    private static async setUSPAmenities(props: USPAmenitiesProps, tthis?: USPAmenitiesWidget): Promise<void> {
        const { notifyParentNoDynamicContentWasFound } = props;
        try {
            tthis?.cancelFetchingAmenities?.();
            const { finalAmenities, amenityIcons, categoryLabel, amenityImages, cancelFetch } =
                (await getUspAmenities(props, { callback: () => notifyParentNoDynamicContentWasFound?.(this), thisContext: this })) ?? {};
            if (tthis) {
                tthis.cancelFetchingAmenities = cancelFetch;
            }
            tthis?.setState({
                amenityLinks: finalAmenities || [],
                amenityIcons: amenityIcons || [],
                amenityImages: amenityImages || [],
                amenityCategory: categoryLabel || "",
            });
        } catch (err) {
            return;
        }
    }

    private handleAmenity = (amenityId: string): void => {
        const action: FilterChangeAction = {
            type: ActionType.FilterChange,
            filter: dynamicFilterType.addamenity,
            payload: {
                amenities: [amenityId],
            },
        };
        this.props.dispatchAction(action);
    };

    private getAmenityFieldsData = (amenityLink: MXTS.AmenityLink): JSX.Element | JSX.Element[] => {
        const { options } = this.props;
        if (options.amenityFieldMultiSelect?.length) {
            return options.amenityFieldMultiSelect.map((field, index) => (
                <span key={index} className={field.value === MultiSelectOptions.TITLE ? "amenity-title" : "amenity-description"}>
                    {(field.value === MultiSelectOptions.TITLE && amenityLink.amenity.name) || (field.value === MultiSelectOptions.DESCRIPTION && amenityLink.amenity.description)}
                </span>
            ));
        }
        return <span className="amenity-title">{amenityLink.amenity.name}</span>;
    };

    private loadNoResultFoundFallback = async () => {
        const { options, context } = this.props;
        this.setState({ fallbackWebContent: await getWebContentById(options.fallbackWebContentId), fallbackTemplate: await renderTemplateById(options.fallbackTemplateId, context) });
    };
}

function mapStateToProps(state: State): USPAmenitiesStoreProps {
    return {
        dynamicFilter: state.dynamicFilter,
        myEnvState: state.myEnvState,
        availabilityState: state.availabilityState,
    };
}

function mapDispatchToProps(dispatch: Dispatch<FilterChangeAction>): USPAmenitiesDispatchProps {
    return {
        dispatchAction: dispatch,
    };
}

// eslint-disable-next-line max-len
const UspAmenities = connect<USPAmenitiesStoreProps, USPAmenitiesDispatchProps>(mapStateToProps, mapDispatchToProps)(USPAmenitiesWidget);

const DynamicUSPAmenities = wrapProps<DynamicWidgetBaseProps<WidgetOptions, UspAmenitiesResponse>>(UspAmenities);

export function uspAmenitiesWidget(type: WidgetType) {
    const uspAmenities: WidgetSpec<WidgetOptions> = {
        id: "uspAmenitiesWidgetWidget",
        type,
        widgetGroup: type === "page" || type === "resultsPanel" ? WidgetGroup.DYNAMIC : WidgetGroup.OTHER,
        name: getI18nLocaleObject(namespaceList.amenitiesWidget, "uspAmenityWidget"),
        description: getI18nLocaleObject(namespaceList.amenitiesWidget, "uspAmenityWidgetDescription"),
        optionsForm: widgetOptionsForm,
        defaultOptions: (): WidgetOptions => ({
            amenityGroupType: "typesearch",
            defaultNumberOfAmenities: 5,
            amenityCategories: [],
            infoSeparation: "",
            addIcon: false,
            displayOnlyIcon: false,
            showBadge: false,
            badgeBackgroundColor: "",
            uspTextColor: "color-brand",
            uspIconColor: "",
            sortLinksByPriority: false,
            enableBookingsEngineLink: false,
            showAmenityField: false,
            amenityFieldMultiSelect: [],
            showIconAtTop: false,
        }),
    };
    (uspAmenities as PageWidgetSpec<WidgetOptions> | TypesearchContainerWidgetSpec<WidgetOptions>).render = async (
        widget: Widget<WidgetOptions>,
        context: CMSProvidedProperties,
        sitemapPageLinkWidgetOptions?: SitemapPageLinkWidgetOptions,
        resultOptions?: ResultOptions,
        dynamicContainerOptions?: DynamicContainerOptions
    ) => {
        const { styleIds } = widget.options;
        const TARGETS = ["dynamic-container", "usp-amenities"];
        const className = findMultiSelectStyleClassNames(context.theme, TARGETS, styleIds || []);
        const reduxStoreState = context.reduxStore.store.getState();

        const props: ServerSideProps<"USPAmenityWidgetProps"> = {
            context,
            options: widget.options,
            dynamicFilter: reduxStoreState?.dynamicFilter,
            myEnvState: reduxStoreState?.myEnvState,
            availabilityState: reduxStoreState?.availabilityState,
            dynamicContainerOptions,
        };

        const fetchedData = await getUspAmenities({ ...props });
        if (isServerSide() && (!resultOptions || Object.keys(resultOptions).length > 0)) {
            await USPAmenitiesWidget.warmupCache({
                options: widget.options,
                context,
                dynamicContainerOptions,
                availabilityState: {},
                dispatchAction: context.reduxStore.store.dispatch,
                dynamicFilter: context.reduxStore.store.getState().dynamicFilter,
                myEnvState: {},
                ...(resultOptions || {}),
            });
        }
        return <DynamicUSPAmenities fetchedData={fetchedData} dynamicContainerOptions={dynamicContainerOptions} options={widget.options} context={context} className={className} />;
    };
    return uspAmenities;
}
